import React, { useEffect, useState } from 'react';
import app, {auth} from './base';

const db = app.firestore();

const Home = () => {
  const [pushTokens, setPushTokens] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPushCollection = async (db, title, body) => {
    const pushTokens = db.collection('ExponentPushToken');
    await pushTokens.get().then((querySnapshot) => {
      const tokens = querySnapshot.docs.map((doc) => {
        return doc.data().token;
       
      });
      const message = {
        to: tokens,
        title: title,
        sound: 'default',
        body: body,
      }
      setPushTokens(message);
      setLoading(false);
    });
  };

  const handleMessage = (event) => {
    event.preventDefault();
    const { title, description } = event.target;

    getPushCollection(db, title.value, description.value);
  };

  useEffect(() => {
    async function sendPushNotification() {
    if (!loading) {
      console.log(pushTokens);
        const requestOptions = {
          mode: 'no-cors',
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: JSON.stringify(pushTokens),
        };
        await fetch(
          'https://exp.host/--/api/v2/push/send',
          requestOptions
        ).catch((err) => console.log(err));
      }
    }
    sendPushNotification();
  }, [loading, pushTokens]);

  return (
    <>
      <div className='wrapper-center'>
        <div className='form'>
          <form onSubmit={handleMessage}>
            <h1>Message</h1>
            {!loading && pushTokens && (
              <div className='alert alert-success' role='alert'>
                Message sent
              </div>
            )}
            <div className='form-group'>
              <label htmlFor='inputTitle'>Title</label>
              <input
                name='title'
                type='text'
                className='form-control'
                id='inputTitle'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='description'>Description</label>
              <textarea
                name='description'
                className='form-control'
                id='description'
                rows='4'
              ></textarea>
            </div>
            <div className='buttons-wrapper'>
              <button type='submit' className='btn btn-primary'>
                Submit
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => auth.signOut()}
              >
                Sign out
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Home;
