import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAaHqz88uygVHuXsbLFCiTNp3xZd7NDuD8",
  authDomain: "terralex-push.firebaseapp.com",
  databaseURL: "https://terralex-push.firebaseio.com",
  projectId: "terralex-push",
  storageBucket: "terralex-push.appspot.com",
  messagingSenderId: 1091796509287
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export default app;