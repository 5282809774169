import React, { useCallback, useContext } from 'react';
import { withRouter, Redirect } from 'react-router';
import {auth} from './base.js';
import { AuthContext } from './Auth.js';

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await auth.signInWithEmailAndPassword(email.value, password.value);
        history.push('/');
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to='/' />;
  }

  return (
    <div className='wrapper-center'>
      <div className='form'>
        <div className='form-group'>
          <h1>Log in</h1>
          <form onSubmit={handleLogin}>
            <div className='form-group'>
              <label htmlFor='inputEmail1'>Email</label>
              <input
                name='email'
                type='email'
                className='form-control'
                id='inputEmail1'
                placeholder='Email'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='inputPassword'>Password</label>
              <input
                name='password'
                type='password'
                placeholder='Password'
                className='form-control'
                id='inputPassword'
              />
            </div>
            <button type='submit' className='btn btn-primary'>
              Log in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
